import "./styles.css";
import { showDialog } from "../Common/utils";
import Test from "../Auth/Test";
import Logo from "../../images/LOGO.png";
export const NavBar = () => {
  const isAuthenticated =
    sessionStorage.getItem("profile") === null ? false : true;

    const loginName = isAuthenticated? JSON.parse(sessionStorage.getItem("profile")).name.replace(
          /"([^"]+)":/g,"$1:"): null

  const toggleMenu = (e) => {
    const menu = document.getElementById("sub-menu");    
    menu.className === "sub-menu"
      ? (menu.className += "  hidden")
      : (menu.className = "sub-menu");
  };
  const openDialog = ()=>{
    const dialog = document.getElementById("form-log")
    dialog.showModal()
  }

  return (
    <div className="topnav" id="myTopnav">
      <img className="logo" src={Logo} alt="logo" />
      <div className="burger fa-solid fa-bars" onClick={toggleMenu}></div>
      <div id="sub-menu" className="sub-menu hidden">
        <a href="/">{"Home"}</a>
        <a href="/contact">{"Directions/ Contact"}</a>
        <a href="/playing">{"Playing"}</a>
        <a href="/membership">{"Membership"}</a>
        <a href="/coaching">{"Coaching"}</a>
        <a href="/events">{"Events"}</a>
        <a href="/safeguarding">{"Safeguarding"}</a>
        {/* <button className="fa-solid fa-bars" onClick={openDialog}/> */}
        {/* <a href="/logIn">{isAuthenticated?"Log out: " + loginName:"Log in"}</a> */}
      </div>
      {/* <dialog id="form-log" className="modal-form-log"> */}
    {/* <Test/> */}

    {/* </dialog> */}
    </div>
  );
};

export default NavBar;
