import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Modal, Button, ButtonToolbar } from "rsuite";
import LogReg from "./Modals/LogReg";
import ForgotPw from "./Modals/ForgotPw";
import Logout from "./Modals/Logout";
import ChangePw from "./Modals/ChangePw";
import "./styles.css"

const Auth = () => {
  const navigate = useNavigate();

  const isLogged = sessionStorage.getItem("profile") === null ? false : true;
  const [show, setShow] = useState(true);
  const [isReg, setIsReg] = useState(false);
  const [forgotPw, setForgotPw] = useState(false);
  const [changePw, setchangePw] = useState(useParams().token !== undefined);

  const handleClose = () => {
    setShow(false);
    navigate("/");
    navigate(0);
  };

  const handleReg = () => {
    setIsReg(true);
  };

  const handlePwRequest = () => {
    setForgotPw(true);
  };

  const ButtonBar = () => {
    return (
      <ButtonToolbar>
        <Button
          hidden={forgotPw || isReg || changePw || isLogged}
          size='md'
          onClick={handleReg}
          title='No account? Click here to register'
        >
          No Account? Register
        </Button>
        <Button
          hidden={forgotPw || isReg || changePw || isLogged}
          size='md'
          onClick={handlePwRequest}
          title='Forgot your password? click here to reset'
        >
          Forgotten Password
        </Button>

        <Button onClick={handleClose} size='md'>
          Cancel
        </Button>
      </ButtonToolbar>
    );
  };

  return (

    <dialog className="modal-form" open={show}>
      {isLogged ? (
        <Logout />
      ) : forgotPw ? (
        <ForgotPw />
      ) : changePw ? (
        <ChangePw />
      ) : (
        <LogReg isReg={isReg}></LogReg>
      )}
      <br />
      <ButtonBar />
    </dialog>

  );
};
export default Auth;
