import React from 'react'
import "./styles.css"
const FormInputBox = ({value, placeholder, label, name, onChange, type}) => {
return <div>
    <label htmlFor={label}>{`${label}: `}</label>
<input id={label} 
value={value}
placeholder={placeholder} 
name={name}
onChange={onChange}
type={type}
/>
</div>
}
export default FormInputBox