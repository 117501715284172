import React from "react";
import mainImage from "../../images/MTC_Overhead.jpg";
import "./styles.css";
function Home() {
  return (
    <div className="page">
      <h1 className="head">Welcome to Misterton Tennis Club</h1>
      <h2 className="strap">Enjoy Your Tennis!</h2>
      <img className="main-img" src={mainImage} alt="mainImage" />
      <div className="text-content">
        <p>
          We are based in the village of{" "} 
          <a href="/contact" title="location">
            Misterton
          </a>
          , just south of Crewkerne in Somerset.
          <br />
          <br />
          We pride ourselves on being a friendly and welcoming club to any
          player of any standard and of any age. Although we are a small club,
          we have excellent playing facilities. Our two separate, but adjoining
          courts have been recently re-surfaced and our floodlights mean that we
          can continue playing well into winter evenings.
          <br />
          <br />
          We organise several <a href="/events">tournaments</a> throughout the
          year and have a number of open club sessions per week. We also
          undertake competitive matches in the Yeovil & District league. We play
          in both the summer and winter leagues – so competitive play is
          available all the year round.
          <br />
          <br />
          We also run a range of social events, offering something for everyone.
          You can join in as much or as little as you want.
          <br />
          <br />
          For any new players or players feeling a bit rusty, we have the
          services of a LTA Level 2 qualified coach, who offers both adult and
          junior coaching.
          <br />
          <br />
          If you are interested in joining us, we have very attractive{" "}
          <a href="/events">membership</a> rates
        </p>
        <br />
        <br /> <h4>Contact the club secretary on 07702 242000</h4>
        <a href="mailto:admin@mistertontennis.co.uk">
          email: admin@mistertontennis.co.uk
        </a>
      </div>
      <br />
      <br />
    </div>
  );
}

export default Home;
