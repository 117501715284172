import { useState, } from "react";
import { useNavigate } from "react-router-dom";
import { closeDialog } from "../Common/utils";
import FormActionButton from "../Common/FormControls/FormActionButton";
import FormInputBox from "../Common/FormControls/FormInputBox";
import "./styles.css";

const Test = () => {
  const isLogged = sessionStorage.getItem("profile") === null ? false : true;
  const [status, setStatus] = useState("login");

const Navigate = useNavigate()

  const handleClose = () => {
closeDialog("form-log")
    Navigate("/")
  };

  const handleReg = () => {
    setStatus("register");
  };

  const handlePwRequest = () => {
    setStatus("pwReq");
  };
  return (

      <div className="login-frame">
      <FormActionButton
        className={"btn btn-canx"}
        btnText={"cancel"}
        onClick={handleClose}
      />
      </div>
 
  );
};
export default Test;
