import { React, useState } from "react";
import FormInputBox from "../../Common/FormControls/FormInputBox";
import { closeDialog } from "../../Common/utils"
import { editEvent, createEvent } from "../../../api";

const EventDialog = ({ event, update }) => {
  const initialState = {
    what: "",
    when: "",
    link: "",
    linkText: "",
  };
  const [formData, setFormData] = useState(initialState);



  if (!formData.initialised && event) {
    const myWhen = event.when.substring(0,23);
    
    setFormData({
      initialised: true,
      _id:event._id,
      what: event.what,
      when: myWhen,
      link: event.link,
      linkText: event.linkText,
    });
  }

  const handleSubmit = async () => {
    if (event) {
      console.log(formData);
     editEvent(formData);
    } else {
      console.log("Creating event");
     createEvent(formData);
    }

    setFormData(initialState);
    update(null);
    closeDialog("eventDialog");
  };

  const handleChange = (e) => {
    e.preventDefault();
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <dialog id="eventDialog" className="dialog-event">
      <h1>{!event ? "New Event" : "Edit Event"}</h1>

      <FormInputBox
        placeholder={formData.what}
        value={formData.what}
        label="What"
        name="what"
        onChange={handleChange}
      />
      <FormInputBox
      type="datetime-local"
        placeholder={formData.when}
        value={formData.when}
        label="When"
        name="when"
        onChange={handleChange}
      />
      <FormInputBox
        placeholder={formData.link}
        value={formData.link}
        label="Link"
        name="link"
        onChange={handleChange}
      />
      <FormInputBox
        placeholder={formData.linkText}
        value={formData.linkText}
        label="Link Text"
        name="linkText"
        onChange={handleChange}
      />

      <button onClick={handleSubmit}>Submit</button>
    </dialog>
  );
};
export default EventDialog;
