


  export const showDialog = () => {
    const dialog = document.getElementById("eventDialog")
    dialog.showModal()
  };
  export const closeDialog = () => {
    const dialog = document.getElementById("eventDialog");
    dialog.close()
  };
