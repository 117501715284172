import { React, useState } from "react";
import mainImage from "../../images/MTC_Events.jpg";
import "./styles.css";
import { getEvents } from "../../api";
import EventWidget from "./Components/EventWidget";
import EventDialog from "./Components/EventDialog";
import { showDialog } from "./Components/utils/utils";

const Events = () => {
  const [eventList, setEventList] = useState(null);
  const [activeEvent, setActiveEvent] = useState(null);
  
  const auth = JSON.parse(sessionStorage.getItem("profile"));
  const isAdmin = auth?auth.class==="admin":false


  const getEventList = async () => {
    try {
      const response = await getEvents();
      const events = await response.data.result;
      setEventList(events);
    } catch (err) {
      console.log(err);
    }
  };

  const eventTitles = [];
//wait for event list to import
  if (!eventList) {
    getEventList();
  } else {
    eventList.forEach((event) => {
      eventTitles.push(EventWidget(event, setActiveEvent));
    });
  }


const newDialog = (e) =>{
   showDialog(e)
}

const updateEvent=(e)=>{  
setActiveEvent(e)
window.location.reload()
}

  return (
    <div className="page-events">
      <h1 className="head">What's Happening</h1>
      <h3 className="strap">Upcoming Events</h3>
      <img className="main-img" src={mainImage} alt="mainImage" />
              <EventDialog event = {activeEvent} update={updateEvent}/>
        <table className="table-main">
        <thead>
          <tr className="table-row table-head">
            <td>Event</td>
            <td>Date: time</td>
            <td>Sign-up</td>
          </tr>
        </thead>
        <tbody>{eventTitles}</tbody>
      </table>
      <button hidden={!isAdmin} onClick={newDialog}>New Event</button>
    </div>
  );
};
export default Events;
