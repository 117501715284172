import React from "react";

import NavBar from "./components/NavZone/Navbar";
import { Routes, Route } from "react-router-dom";
import Auth from "./components/Auth/Auth";
import Test from "./components/Auth/Test";
import Home from "./components/Home/Home";
import Contact from "./components/Contact/Contact";
import Membership from "./components/Membership/Membership";
import Coaching from "./components/Coaching/Coaching";
import Playing from "./components/Playing/Playing";
import Events from "./components/Events/Events";


import Safeguarding from "./components/Safeguarding/Safeguarding";
import Privacy from "./components/Safeguarding/Privacy";

import Footer from "./components/Footer/Footer";
import SideBar from "./components/Sidebar/Sidebar";

import "./styles/styles.css";

const App = () => {  
  return (
    <div className="site-style site-grid">
      <header>
        <NavBar />
      </header>

      <aside>
        <SideBar />
      </aside>
      <main>
        <Routes>
          <Route path="/pwchange/:token" element={<Auth />} />
          <Route path="/login" element={<Auth />} />
          <Route path="/test" element={<Test />} />
          <Route path="/" element={<Home />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/membership" element={<Membership />} />
          <Route path="/coaching" element={<Coaching />} />
          <Route path="/playing" element={<Playing />} />
          <Route path="events/" element={<Events />} />
          <Route path="/safeguarding" element={<Safeguarding />} />
          <Route path="/privacy" element={<Privacy />} />
        </Routes>
      </main>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};
export default App;
