import { dropEvent } from "../../../api";
import { showDialog } from "./utils/utils";


const EventWidget = (event, setActiveEvent) => {

    const auth = JSON.parse(sessionStorage.getItem("profile"));
    const isAdmin = auth?auth.class==="admin":false

  const d = new Date(event.when);

  const eventDate = d.toDateString();
  const eventTime = d.toLocaleTimeString("en-us", {
    hour12: true,
    hour: "numeric",
    minute: "numeric",
  });

  const editEvent = (e) =>{
    setActiveEvent(event)
    showDialog(e)
  }

  const deleteEvent = () => {  
    dropEvent(event._id);
    window.location.reload()
  };

  return (
      <tr className="table-row" key={event._id}>
        <td className="table-event">{event.what}</td>
        <td className="table-date">
            {eventDate}: {eventTime}
        </td>
        <td>
          <a href={event.link} target="blank">
            <div className=" sign-icon fa-solid fa-user-plus"></div>
          </a>
        </td>
        <td hidden={!isAdmin}>
          <button className="fa-solid fa-pen-nib" onClick={editEvent}></button>
        </td>
        <td hidden={!isAdmin}>
          <button className="fa-solid fa-trash" onClick={deleteEvent}></button>
        </td>
      </tr>
  );
};
export default EventWidget;
